import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {PaletteColor, PaletteColorOptions, Palette} from "@mui/material";

declare module "@mui/material/styles/createPalette" {
  export interface Palette {
    // customOrange: string
    customOrange: PaletteColorOptions,
    customAqua: PaletteColorOptions
    customBlue: PaletteColorOptions
  }
  export interface PaletteOptions {
    customOrange: PaletteColorOptions
    customAqua: PaletteColorOptions
    customBlue: PaletteColorOptions
  }
}

declare module "@mui/lab/TimelineDot"{
  interface TimelineDotPropsColorOverrides {
    customOrange: true
    customAqua: true
    customBlue: true
  }

  interface TimelineOppositeContentPropsColorOverrides {
    customOrange: true
    customAqua: true
    customBlue: true
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
