import React, { FC, ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Toolbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { GitHub, Home, LinkedIn } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import headshot from "../../headshot2.webp";
import Footer from "../../components/footer/Footer";

const useStyles: Function = makeStyles((theme?: Theme) => ({
  appbar: {
    background: "primary.main",
    margin: 0,
  },
  arrow: {
    color: "#39332e",
  },
  title: {
    color: "#39332e",
  },
  menuSliderContainer: {
    width: 250,
    background: "#ffffe4",
    height: "100%",
  },
  avatar: {
    display: "block",
    margin: "0.5rem auto",
    width: theme?.spacing(13),
    height: theme?.spacing(13),
  },
  listItem: {
    color: "secondary.main",
  },
}));

const menuItems = [
  { listIcon: <Home />, listText: "Home", listPath: "/" },
  // {listIcon: <Person/>, listText: "Projects", listPath: "/projects"},
];

const Navbar: FC = (): ReactElement => {
  const [open, setOpen] = useState(false);

  const styles = useStyles();

  const sideList = () => (
    <Box className={styles.menuSliderContainer} component="div">
      <Avatar
        className={styles.avatar}
        src={headshot}
        alt="Zachariah Wiebesiek"
      />
      <Divider />
      <List>
        {menuItems.map((item, i) => (
          <ListItem key={i} className={styles.listItem}>
            <ListItemButton
              key={i}
              onClick={() => setOpen(false)}
              component={Link}
              to={item.listPath}
            >
              <ListItemIcon className={styles.listItem}>
                {item.listIcon}
              </ListItemIcon>
              <ListItemText primary={item.listText} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <React.Fragment>
      <AppBar position="sticky" className={styles.appbar}>
        <Toolbar>
          <IconButton onClick={() => setOpen(true)}>
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton href="https://www.linkedin.com/in/zach-wiebesiek/">
            <LinkedIn />
          </IconButton>
          <IconButton href="https://github.com/Wiebesiek">
            <GitHub />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer open={open} anchor="left" onClose={() => setOpen(false)}>
        {sideList()}
        <Footer />
      </Drawer>
    </React.Fragment>
  );
};

export default Navbar;
