import React, {FC, ReactElement} from "react";
import ReactParticles from "react-particles";
import {loadFull} from "tsparticles";
import {useCallback} from "react";
import {Container, Engine} from "tsparticles-engine";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";

const useStyles: Function = makeStyles((theme?: Theme) => ({
  particlesCanvas: {
    position: "fixed",
    opacity: "0.6"
  },
}));

const Particles: FC = (): ReactElement => {
  const styles = useStyles();
  const particlesInit = useCallback(async (engine: Engine) => {
    console.log(engine);
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: Container | undefined) => {
    await console.log(container);
  }, []);
  return (
    <ReactParticles
      id="tsparticles"
      init={particlesInit}
      canvasClassName={styles.particlesCanvas}
      loaded={particlesLoaded}
      options={{
        background: {
          color: {
            value: "#ab9d92",
          },
        },
        fpsLimit: 120,
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "grab"
            },
            onclick: {
              enable: true,
              mode: "push"
            },
            resize: true
          },
          modes: {
            grab: {
              distance: 140,
              line_linked: {
                opacity: 1
              }
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3
            },
            repulse: {
              distance: 200,
              duration: 0.4
            },
            push: {
              particles_nb: 4
            },
            remove: {
              particles_nb: 2
            }
          }
        },
        particles: {
          color: {
            value: "#50443a",
          },
          links: {
            color: "#50443a",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: "none",
            enable: true,
            outModes: {
              default: "bounce",
            },
            random: true,
            speed: 3,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 80,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "circle",
          },
          size: {
            value: {min: 1, max: 5},
          },
        },
        detectRetina: true,
      }}
    />
  );
};

export default Particles