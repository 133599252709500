import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { FC, ReactElement } from "react";
import { LinkedIn, GitHub } from "@mui/icons-material";

const githubLink = "https://github.com/Wiebesiek";
const linkedInLink = "https://www.linkedin.com/in/zach-wiebesiek/";

const Footer: FC = (): ReactElement => {
  return (
    <BottomNavigation>
      <BottomNavigationAction icon={<LinkedIn />} href={linkedInLink} />
      <BottomNavigationAction icon={<GitHub />} href={githubLink} />
    </BottomNavigation>
  );
};

export default Footer;
