import Home from "./pages/Home";
import Projects from "./pages/Projects";
import {FC} from "react";

interface Route {
  key: string,
  title: string,
  path: string,
  enabled: boolean,
  component: FC<{}>
}

export const routes: Array<Route> = [
  {
    key: 'home-route',
    title: 'Home',
    path: '/',
    enabled: true,
    component: Home
  },
  // {
  //   key: 'experience-route',
  //   title: 'Projects',
  //   path: '/projects',
  //   enabled: true,
  //   component: Projects
  // }
]