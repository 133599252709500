import React from 'react';
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from './theme'
import background from './mountains.webp'
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import {routes} from "./routes";

import "./App.css"

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme/>
      <div style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: '100vw',
        height: '100vh'
      }}
      >
        <Router>
          <Navbar/>
          <Routes>
            {routes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                element={<route.component/>}
              />
            ))}
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
);
}

export default App;
