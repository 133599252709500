import React, {ReactElement, FC} from "react";
import Overlay from "../components/overlay/Overlay";
import Particles from "../components/particles/particles";
const Home: FC<any> = (): ReactElement => {
  return (
    <React.Fragment>
      <Particles/>
      <Overlay/>
    </React.Fragment>
  );
};

export default Home;