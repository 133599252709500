import React, {FC, ReactElement} from "react";
import {Avatar, Box, Grid, Typography, Theme} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typed from "react-typed";
import headshot from "../../headshot2.webp";

const useStyles:Function = makeStyles((theme?: Theme) => ({
  title: {
    color: theme?.palette.primary.dark,
  },
  subtitle: {
    color: theme?.palette.secondary.main,
    textTransform: "uppercase",
  },
  typedContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: "100vw",
    textAlign: "center",
    zIndex: 1,
  },
}));

const Overlay: FC = (): ReactElement => {
  const classes = useStyles();

  return (
    <Box className={classes.typedContainer}>
      <Grid
        container
        justifyContent="center">
        <Avatar
          sx={{
            height:200,
            width:200,
            margin:2,
            boxShadow:"7px 5px 5px",
          }}
          variant="rounded"
          src={headshot}
          alt="Zachariah Wiebesiek"
        />
      </Grid>
      <Typography className={classes.title} variant="h4">
        <Typed strings={["Zachariah Wiebesiek"]} typeSpeed={40} />
      </Typography>

      <Typography className={classes.subtitle} variant="h5">
        <Typed
          strings={[
            "Software Engineer",
            "Vim Enthusiast",
            "Father",
          ]}
          typeSpeed={40}
          backSpeed={50}
          loop
        />
      </Typography>
    </Box>
  );
};

export default Overlay;