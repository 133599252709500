import {createTheme} from "@mui/material/styles";
import {PaletteColor, PaletteColorOptions} from "@mui/material";


let theme = createTheme({
    typography: {
      fontFamily: "Roboto"
    },
    palette: {
      primary: {
        light: "#ab9d92",
        main: "#7c6f64",
        dark: "#50443a",
        contrastText: "#000",
      },
      secondary: {
        main: "#ebdbb2",
        light: "#ffffe4",
        dark: "#b8a982",
        contrastText: "#000"
      },
      customOrange: {
        main: "#fe8109",
        light: "#000000",
        dark: "#000000",
        contrastText: "#000"
      },
      customAqua: {
        main: "#8ec07c",
        light: "#000000",
        dark: "#689d6a",
        contrastText: "#000"
      },
      customBlue: {
        main: "#83a598",
        light: "#000000",
        dark: "#458558",
        contrastText: "#000"
      },
    }
  }
);

// theme = createTheme(theme,
//   {
//     components: {
//       // MuiTimeLineYear:{
//       //   styleOverrides:{
//       //     root:{
//       //       textAlign: "center",
//       //       maxWidth: "9.375rem",
//       //       margin: "0 3rem 0 auto",
//       //       fontSize: "1.8rem",
//       //       color: "#fff",
//       //       background: "tomato",
//       //       lineHeight: 1,
//       //       padding: "0.5rem 1rem",
//       //       "&:before": {
//       //         display: "none",
//       //       },
//       //       [theme.breakpoints.up("md")]: {
//       //         textAlign: "center",
//       //         margin: "0 auto",
//       //         "&:nth-of-type(2n)": {
//       //           float: "none",
//       //           margin: "0 auto",
//       //         },
//       //         "&:nth-of-type(2n):before": {
//       //           display: "none",
//       //         }
//       //       }
//       //     }
//       //   }
//       // },
//       MuiTimeLine: {
//         styleOverrides: {
//           root: {
//             // position: "relative",
//             // padding: "1rem",
//             // margin: "o auto",
//             // "&:before": {
//             //   content: "''",
//             //   position: "absolute",
//             //   height: "100%",
//             //   border: "1px solid tan",
//             //   right: "40px",
//             //   top: 0,
//             // },
//             // "&:after": {
//             //   content: "''",
//             //   display: "table",
//             //   clear: "both",
//             // },
//             // [theme.breakpoints.up("md")]: {
//             //   padding: "2rem",
//             //   "&:before": {
//             //     left: "calc(50% - 1px)",
//             //     right: "auto",
//             //   },
//             // },
//           }
//         }
//       },
//       MuiTimelineOppositeContent: {
//         styleOverrides: {
//           root: {
//             color: theme.palette.secondary.main
//           }
//         }
//       },
//       MuiTimelineItem: {
//         styleOverrides: {
//           root: {
//             // padding: "1rem",
//             // borderBottom: `2px solid ${theme.palette.secondary.light}`,
//             // position: "relative",
//             // margin: "1rem 3rem 1rem 1rem",
//             // clear: "both",
//             // "&:after": {
//             //   content: "''",
//             //   position: "absolute",
//             // },
//             // "&:before": {
//             //   content: "''",
//             //   position: "absolute",
//             //   right: "-0.625rem",
//             //   top: "calc(50% - 5px)",
//             //   borderStyle: "solid",
//             //   borderColor: "tomato tomato transparent transparent",
//             //   borderWidth: "0.625rem",
//             //   transform: "rotate(45deg)",
//             // },
//             // [theme.breakpoints.up("md")]: {
//             //   width: "44%",
//             //   margin: "1rem",
//             //   "&:nth-of-type(2n)": {
//             //     float: "right",
//             //     margin: "1rem",
//             //     borderColor: "tan",
//             //   },
//             //   "&:nth-of-type(2n):before": {
//             //     right: "auto",
//             //     left: "-0.625rem",
//             //     borderColor: "transparent transparent tomato tomato",
//             //   },
//             // }
//           }
//         }
//       }
//     }
//   }
// );
//
// Bottom Nav
theme = createTheme(theme, {
  components: {
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          background: theme.palette.primary.dark,
          "& .MuiSvgIcon-root": {
            fill: theme.palette.primary.light,
            "&:hover": {
              fill: theme.palette.secondary.main,
              fontSize: "1.8rem",
            }
          }
        }
      }
    }
  }
});

export default theme;
